import { combineReducers, createStore } from "redux";
import cultureReducer from "./culture/reducer";
import resultsReducer from "./results/reducer";

const reducers = combineReducers({
  cultureReducer,
  resultsReducer,
});

const store = createStore(reducers);

export default store;
