let initialState = {
  resultadoVuelos: [],
  minutosActualizacion: "",
  nombreOrigenSel: "",
  nombreDestinoSel: "",
  fechaConsulta: "",
  search: false,
  editado: false,
  idOrigen: "",
  labelOrigen: "",
  idDestino: "",
  labelDestino: "",
  nombreOrigen: "",
  flightNumber: "",
  fechaConsultaBack: new Date(),
  segmentSelected: "ruta",
  optionsDestino: [],
};

export const getResults = (state) => {
  return state.resultsReducer;
};

const resultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_RESULTS":
      return { ...state, ...action.payload };
    case "UPDATE_RESULTS_SEARCH":
      return { ...state, search: action.payload };
    case "UPDATE_RESULTS_EDITADO":
      return { ...state, editado: action.payload };
    case "UPDATE_RESULTS_FECHA_CONSULTA":
      return { ...state, fechaConsultaBack: action.payload };
    case "UPDATE_RESULTS_FLIGHT_NUMBER":
      return { ...state, flightNumber: action.payload };
    case "UPDATE_RESULTS_SEGMENT_SELECTED":
      return { ...state, segmentSelected: action.payload };
    case "UPDATE_RESULTS_ORIGEN":
      return {
        ...state,
        idOrigen: action.payload?.value ? action.payload.value : "",
        labelOrigen: action.payload?.label ? action.payload.label : "",
      };
    case "UPDATE_RESULTS_DESTINO":
      return {
        ...state,
        idDestino: action.payload?.value ? action.payload.value : "",
        labelDestino: action.payload?.label ? action.payload.label : "",
      };
    case "RESET_RESULTS_STATE":
      return { ...initialState, segmentSelected: state.segmentSelected };
    case "UPDATE_DESTINO_OPTIONS":
      return { ...state, optionsDestino: action.payload };
    default:
      return state;
  }
};
export default resultsReducer;
