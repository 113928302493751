import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { getCulture } from "../store/culture/reducer";
import {
  updateOptionsDestino,
  updateResultsDestino,
  updateResultsOrigen,
} from "../store/results/action";
import { getResults } from "../store/results/reducer";

const SelectsRuta = (props) => {
  const {
    culture,
    updateResultsOrigen,
    updateResultsDestino,
    updateOptionsDestino,
  } = props;
  const { elements, stationsData } = culture;

  const [selectOptionsOrigen, setSelectOptionsOrigen] = useState([]);
  const [selectOptionsDestino, setSelectOptionsDestino] = useState([]);
  const { validaSelectRuta } = props;
  const { errorsflightRutaOrigin } = props;
  const selectInputRefFlight = useRef(null);
  const selectInputRefFlightOrigen = useRef(null);
  const urlApiTramos = process.env.REACT_APP_ENDPOINT_API_TRAMOS;
  let datoDestino = "";

  const orderArray = stationsData.item.elements.station_2.value;
  let esEdicion = props.results.editado;

  let optionArrSal = [];
  let optionArrSalDestino = [];

  const getOptions = async () => {
    let data = [];
    await axios
      .get(urlApiTramos)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => {
        console.log("Error: Api getCiudades ", err);
        //TODO: ventana modal de error
      });

    let countries = [];

    // Busco en Kentico si el item es type "pais"
    // Si lo es, guardo su country.value e item de pais en kentico

    for (const [key, value] of Object.entries(stationsData.modular_content)) {
      if (stationsData.modular_content[key].system.type === "pais") {
        countries.push({
          country: stationsData.modular_content[key].elements.country.value,
          codename: key,
        });
      }
    }

    // Ordeno los countries segun orderArray de Kentico
    countries.sort(
      (a, b) => orderArray.indexOf(a.codename) - orderArray.indexOf(b.codename)
    );
    process.env.REACT_APP_ENV !== "prod" &&
      console.log("countries POST ORDEN ", countries);

    // Filtro data de API TRAMOS por los items que
    // existan en orderArray de Kentico con su formato
    let optionsNew = data
      .filter((p) =>
        orderArray.includes(
          p.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .split(" ")
            .join("_")
            .split(",")
            .join("")
        )
      )
      .map((d) => {
        const formattedName = d.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .split(" ")
          .join("_")
          .split(",")
          .join("");

        const normalizedCountryName =
          stationsData.modular_content[formattedName]?.elements.country.value[0]
            .name;

        const foundCountryCode = countries.find(
          (f) => f.country === normalizedCountryName
        );

        return {
          label: normalizedCountryName || d.c_name,
          Group1: normalizedCountryName || d.c_name,
          option1: d.name,
          value: d.id,
          codename: foundCountryCode
            ? foundCountryCode.codename
            : "NOT FOUND IN KENTICO",
        };
      });

    process.env.REACT_APP_ENV !== "prod" &&
      console.log("optionsNew POST FILTERMAP ", optionsNew);

    const paises = [...new Set(countries.map((m) => m.country))];

    process.env.REACT_APP_ENV !== "prod" && console.log("paises: ", paises);

    setSelectOptionsOrigen(optionsNew);

    //dejar primero el pais de la cultura seleccionada
    paises.forEach(function (item, i) {
      if (item === props.cultura) {
        paises.splice(i, 1);
        paises.unshift(item);
      }
    });

    for (let pais in paises) {
      let filtered = optionsNew.filter((p) => p.label === paises[pais]);

      // Array a sortear: filtered
      // Ordena segun orderArray desde Kentico
      // replace elimina caracteres especiales como acentos para poder comparar
      filtered.sort(
        (a, b) =>
          orderArray.indexOf(
            a.option1
              .toLowerCase()
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "")
              .split(" ")
              .join("_")
              .split(",")
              .join("")
          ) -
          orderArray.indexOf(
            b.option1
              .toLowerCase()
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "")
              .split(" ")
              .join("_")
              .split(",")
              .join("")
          )
      );

      let optionSelect = {
        label: paises[pais],
        options: filtered.map((e) => {
          return { label: `${e.option1} (${e.value})`, value: `${e.value}` };
        }),
      };

      optionArrSal.push(optionSelect);
    }

    setSelectOptionsOrigen(optionArrSal);

    // RECARGA LOS DESTINOS AL EDITAR
    if (props.editado) {
      const datoOrigen = {
        value: props.idorigen,
        label: props.labelOrigen,
      };

      datoDestino = {
        value: props.iddestino,
        label: props.labelDestino,
      };
      handleChange(datoOrigen);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  const handleChange = async (e) => {
    window.$("html, body").animate({}, "slow");
    props.loadingRef?.current?.startLoader();
    updateResultsDestino("");

    let destinosOption = [];

    if (e) {
      updateResultsOrigen(e);
      setSelectOptionsDestino(destinosOption);

      let result = [];
      await axios
        .get(urlApiTramos)
        .then((res) => {
          result = res.data;
          props.loadingRef?.current?.stopLoader();
        })
        .catch((err) => {
          console.log("Error: Api getCiudades ", err);
          props.loadingRef?.current?.stopLoader();
          //TODO: ventana modal de error
        });

      result = result
        .filter((f) =>
          orderArray.includes(
            f.name
              .toLowerCase()
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "")
              .split(" ")
              .join("_")
              .split(",")
              .join("")
          )
        )
        .sort(
          (a, b) =>
            orderArray.indexOf(
              a.name
                .toLowerCase()
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")
                .split(" ")
                .join("_")
                .split(",")
                .join("")
            ) -
            orderArray.indexOf(
              b.name
                .toLowerCase()
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")
                .split(" ")
                .join("_")
                .split(",")
                .join("")
            )
        );

      const cantidadDatos = Object.keys(result).length;
      const listaOrigenes = Object.keys(result).length;

      let optionsDestinos = {
        value: "",
        label: "",
      };

      let destinosEncontrados = [];
      for (let index = 0; index < cantidadDatos; index++) {
        if (e.value === result[index].id) {
          destinosEncontrados = result[index].destinations;

          for (let i = 0; i < destinosEncontrados.length; i++) {
            for (let j = 0; j < listaOrigenes; j++) {
              if (result[j].id === destinosEncontrados[i]) {
                const formattedName = result[j].name
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/\p{Diacritic}/gu, "")
                  .split(" ")
                  .join("_")
                  .split(",")
                  .join("");

                const normalizedCountryName =
                  stationsData.modular_content[formattedName].elements.country
                    .value[0].name;

                optionsDestinos = {
                  label: normalizedCountryName || result[j].c_name,
                  Group1: normalizedCountryName || result[j].c_name,
                  option1: result[j].name,
                  value: result[j].id,
                };

                if (result[j].id === props.iddestino) {
                  optionsDestinos.selected = true;
                }

                destinosOption.push(optionsDestinos);
              }
            }
          }
        }
      }

      ///////////////////////////////////////////////////////////////////////////////////////////////////

      let paises = [];

      //todos los paises
      for (let pais of destinosOption) {
        let filtered = paises.filter((p) => p === pais.label);
        if (filtered.length === 0) {
          paises.push(pais.label);
        }
      }

      //dejar primero el pais de la cultura seleccionada
      paises.forEach(function (item, i) {
        if (item === props.cultura) {
          paises.splice(i, 1);
          paises.unshift(item);
        }
      });

      //agrupar
      for (let pais in paises) {
        let filtered = destinosOption.filter((p) => p.label === paises[pais]);
        // Array a sortear: filtered
        // Ordena segun orderArray desde Kentico
        // replace elimina caracteres especiales como acentos para poder comparar
        filtered.sort(
          (a, b) =>
            orderArray.indexOf(
              a.option1
                .toLowerCase()
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")
                .split(" ")
                .join("_")
                .split(",")
                .join("")
            ) -
            orderArray.indexOf(
              b.option1
                .toLowerCase()
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")
                .split(" ")
                .join("_")
                .split(",")
                .join("")
            )
        );

        let optionSelect = {
          label: paises[pais],
          options: filtered.map((e) => {
            return { label: `${e.option1} (${e.value})`, value: `${e.value}` };
          }),
        };
        optionArrSalDestino.push(optionSelect);
      }

      ////////////////////////////////////////////////////////////////////////////////////////////////////

      //setSelectOptionsDestino(destinosOption);
      setSelectOptionsDestino(optionArrSalDestino);
      updateOptionsDestino(optionArrSalDestino);
    }

    props.validaSelectRutaOrigin();

    props.loadingRef?.current?.stopLoader();

    ////////////////////////////////////////////////////////////////////////////////////////////////////Agregar destino en caso de edicion

    if (esEdicion) {
      datoDestino = {
        value: props.iddestino,
        label: props.labelDestino,
      };
      selectInputRefFlight.current.setValue({
        label: props.labelDestino,
        value: props.iddestino,
      });

      esEdicion = false;
    }
  };

  ///////////////////////////////////////////////////////////////////validar el no ingreso de caracteres

  const handleChangeRemSpecChar = (newValue) => {
    // const inputValue = newValue.replace(/[^\w\s]/gi, "").replace(/\d+/g, "");

    let inputValue = "";
    let regex = new RegExp("^[a-zA-Z ]+$");

    if (regex.test(newValue)) {
      inputValue = newValue;
    }

    return inputValue;
  };

  const handleDestinoInput = (e) => {
    validaSelectRuta();
    updateResultsDestino(e);
  };
  return (
    <div id="opt-1" className={`row ${props.checkRuta ? "" : "d-none"}`}>
      <div className="col-12 mt-3 text-left">
        <Select
          ref={selectInputRefFlightOrigen}
          options={selectOptionsOrigen}
          onChange={handleChange}
          onInputChange={handleChangeRemSpecChar}
          placeholder={elements.opt_1_origin ? elements.opt_1_origin.value : ""}
          id="origen"
          name="origen"
          value={
            props.labelOrigen && props.idorigen
              ? { label: props.labelOrigen, value: props.idorigen }
              : ""
          }
        />

        {errorsflightRutaOrigin && (
          <span className="text-danger text-small d-block mb-2">
            {elements.opt_1_origin_error
              ? elements.opt_1_origin_error.value
              : ""}
          </span>
        )}
      </div>
      <div className="col-12 mt-3 text-left">
        <Select
          id="selectInputRefFlight"
          ref={selectInputRefFlight}
          options={props.results.optionsDestino}
          placeholder={
            elements.opt_1_destination ? elements.opt_1_destination.value : ""
          }
          onChange={(e) => handleDestinoInput(e)}
          onInputChange={handleChangeRemSpecChar}
          name="destino"
          value={
            props.labelDestino && props.iddestino
              ? { label: props.labelDestino, value: props.iddestino }
              : ""
          }
        />
        {props.errorsFlightRutaDestination && (
          <span className="text-danger text-small d-block mb-2">
            {elements.opt_1_destination_error
              ? elements.opt_1_destination_error.value
              : ""}
          </span>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
    results: getResults(state),
  };
};

export default connect(mapStateToProps, {
  updateResultsOrigen,
  updateResultsDestino,
  updateOptionsDestino,
})(SelectsRuta);
