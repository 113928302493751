import React from "react";
import DropdownFlag from "./DropdownFlag";

const DropdownMenu = ({ cultureList, updateLoader }) => {
  return (
    <div className="dropdown-menu" style={{ minWidth: "11rem" }}>
      {cultureList.map(
        ({ name: countryCode, country: countryName, language }, index) => (
          <a
            href={
              `/${countryCode.value}/${language.value}/${process.env.REACT_APP_ROUTE_NAME}` ||
              process.env.PUBLIC_URL
            }
            onClick={() => updateLoader(false)}
            className="dropdown-item"
            key={`${countryCode}-${index}`}
          >
            <DropdownFlag country={countryCode.value} />
            {countryName?.value || ""}
          </a>
        )
      )}
    </div>
  );
};

export default DropdownMenu;
