import parse from "html-react-parser";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCulture } from "../store/culture/reducer";
import { updateResultSearch, updateResults } from "../store/results/action.js";
import { getResults } from "../store/results/reducer";
import StatusVueloATiempo from "./StatusVueloATiempo";
import StatusVueloAterrizo from "./StatusVueloAterrizo";
import StatusVueloCancelado from "./StatusVueloCancelado";
import StatusVueloDemorado from "./StatusVueloDemorado";
import StatusVueloEnVuelo from "./StatusVueloEnVuelo";
import StatusVueloSinDatos from "./StatusVueloSinDatos";
import VueloMultileg from "./VueloMultileg";

const Resultados = (props) => {
  const { culture, results, updateResultSearch } = props;
  const history = useHistory();
  const bodyRef = useRef();
  const elements = culture.elements;
  useEffect(() => {
    bodyRef.current &&
      bodyRef.current.scrollIntoView(true, { behavior: "smooth" });
  }, []);

  const handleBack = () => {
    updateResultSearch(true) && history.goBack();
  };

  const flightResults = useMemo(() => {
    let tempResultHolder = [];
    let invLegIdHolder = [];
    const groupByFlight = (list) => {
      for (let i = 0; i < list.length; i++) {
        const e1 = list[i];
        let direct = 1;

        // Loop a vuelos con doble pointer
        for (let j = 0; j < list.length; j++) {
          const e2 = list[j];
          // Si vuelo1 y vuelo2 comparten flight number
          // y arrival  de 1 es departure de 2
          // Es vuelo multileg
          if (
            e1.flightNumber === e2.flightNumber &&
            i !== j &&
            !invLegIdHolder.includes(e1.inventoryLegID) &&
            !invLegIdHolder.includes(e2.inventoryLegID) &&
            e1.arrivalStation === e2.departureStation
          ) {
            invLegIdHolder.push(...[e1.inventoryLegID, e2.inventoryLegID]);
            tempResultHolder.push([e1, e2]);
            direct = 0;
          }
        }
        // Si no cumple condicion anterior, es vuelo directo
        if (direct === 1 && !invLegIdHolder.includes(e1.inventoryLegID)) {
          invLegIdHolder.push(e1.inventoryLegID);
          tempResultHolder.push([e1]);
        }
      }
      return tempResultHolder;
    };
    return groupByFlight(results.resultadoVuelos);
  }, [results.resultadoVuelos]);

  process.env.REACT_APP_ENV !== "prod" &&
    console.log("Flightresults: ", flightResults);

  return (
    <div id="step-2" className="container mt-5">
      <div className="col-12 text-center">
        <h1 className="font-weight-normal text-uppercase">
          {elements.search_title ? elements.status_title.value : ""}
        </h1>
      </div>
      <div className="row mt-4" ref={bodyRef}>
        <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 ml-auto d-flex flex-column justify-content-center">
          <h5 className="mb-0">
            {elements.status_flight ? elements.status_flight.value : ""}
          </h5>
          <h5 id="route" className="font-weight-bold">
            {results.nombreOrigenSel.toUpperCase()} -{" "}
            {results.nombreDestinoSel.toUpperCase()}
          </h5>
        </div>
        <div className="col-12 col-sm-6 col-md-3 col-xl-3 mr-auto d-flex align-items-center">
          <button
            className="btn btn-red mt-0 m-sm-0"
            onClick={() => history.goBack()}
          >
            {elements.status_edit ? elements.status_edit.value : ""}
            <i className="far fa-pencil pl-1 pl-sm-2"></i>
          </button>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-2 col-sm-1 d-flex align-items-center justify-content-center"></div>
        <div className="col-8 col-sm-10 text-center">
          <h5 className="font-weight-bold">{results.fechaConsulta}</h5>
          <h6 className="text-muted">
            {elements.status_update_text
              ? elements.status_update_text.value
              : ""}
            <span id="last-update"> {results.minutosActualizacion} min.</span>
          </h6>
        </div>
        <div className="col-2 col-sm-1 d-flex align-items-center justify-content-center"></div>
      </div>
      <div className="row">
        {/* componente de vuelo//////////////////////////////////////////////////////// */}
        {flightResults.length === 0 && (
          <StatusVueloSinDatos handleBack={handleBack} />
        )}
        {flightResults.length > 0 &&
          flightResults.map((flights, i) => (
            <Fragment key={i}>
              {flights.length === 1 &&
                flights.map((vuelo, index) => (

                  

                  <Fragment key={vuelo.flightNumber + index}>

                    

                    <div
                      id="landed"
                      className="col-12 mb-4"
                      hidden={vuelo.estado !== "Cancelado"}
                    >
                      <StatusVueloCancelado
                        vuelo={vuelo}
                        nombreOrigenSel={results.nombreOrigenSel}
                        nombreDestinoSel={results.nombreDestinoSel}
                      />
                    </div>
                    <div
                      id="landed"
                      className="col-12 mb-4"
                      hidden={vuelo.estado !== "Aterrizó"}
                    >
                      <StatusVueloAterrizo
                        vuelo={vuelo}
                        nombreOrigenSel={results.nombreOrigenSel}
                        nombreDestinoSel={results.nombreDestinoSel}
                      />
                    </div>
                    <div
                      id="landed"
                      className="col-12 mb-4"
                      hidden={vuelo.estado !== "En vuelo"}
                    >
                      <StatusVueloEnVuelo
                        vuelo={vuelo}
                        nombreOrigenSel={results.nombreOrigenSel}
                        nombreDestinoSel={results.nombreDestinoSel}
                      />
                    </div>
                    <div
                      id="landed"
                      className="col-12 mb-4"
                      hidden={vuelo.estado !== "Demorado"}
                    >
                      <StatusVueloDemorado
                        vuelo={vuelo}
                        nombreOrigenSel={results.nombreOrigenSel}
                        nombreDestinoSel={results.nombreDestinoSel}
                      />
                    </div>
                    <div
                      id="landed"
                      className="col-12 mb-4"
                      hidden={vuelo.estado !== "A Tiempo"}
                    >
                      <StatusVueloATiempo
                        vuelo={vuelo}
                        nombreOrigenSel={results.nombreOrigenSel}
                        nombreDestinoSel={results.nombreDestinoSel}
                      />
                    </div>
                  </Fragment>
                ))}
              {flights.length > 1 && (
                <VueloMultileg
                  nombreOrigenSel={results.nombreOrigenSel}
                  nombreDestinoSel={results.nombreDestinoSel}
                  filtrados={flights}
                />
              )}
            </Fragment>
          ))}
      </div>
      {elements.status_retry && (
        <div className="row mt-1">
          <button
            className="btn btn-link"
            style={{ textDecoration: "underline" }}
            onClick={handleBack}
          >
            <i className="fal fa-chevron-circle-left align-middle mr-1" />
            {parse(elements.status_retry.value, { trim: true })}
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToresults = (state) => {
  return {
    culture: getCulture(state),
    results: getResults(state),
  };
};

export default connect(mapStateToresults, {
  updateResultSearch,
  updateResults,
})(Resultados);
