const KontentDelivery = require("@kontent-ai/delivery-sdk");
const projectId = process.env.REACT_APP_DELIVERY_ID || "";
const previewApiKey = process.env.REACT_APP_DELIVERY_PREVIEW_TOKEN || "";

const getContentType = async (
  contentTag,
  culture = process.env.REACT_APP_DEFAULT_CULTURE
) => {
  const deliveryClient = KontentDelivery.createDeliveryClient({
    projectId,
    previewApiKey,
    defaultQueryConfig: {
      usePreviewMode: process.env.REACT_APP_ENV !== "prod", // Queries the Delivery Preview API if not Production
    },
  });

  const { response } = await deliveryClient
    .item(contentTag)
    .languageParameter(culture)
    .toPromise();

  return response.data;
};

export default getContentType;
