import React from "react";

const FlightNumberTitle = ({ vuelo, kenticoText }) => {
  return (
    <div className="h5 text-center font-weight-bold mb-0">
      {`${kenticoText} ${vuelo.carrierCode} ${vuelo.flightNumber}`}
      {vuelo.operatingCarrier &&
        ` / ${vuelo.operatingCarrier} ${vuelo.flightNumber}`}
    </div>
  );
};

export default FlightNumberTitle;