const InformationMessage = ({ message, icon }) => {
  return (
    <div className="container dark-blue-bg white-text information-message rounded">
      <div className="row py-2 p-sm-1">
        <div className="col-2 d-flex align-items-start align-items-sm-center">
          <img src={icon} className="img-fluid" alt="" />
        </div>
        <div className="col-10 py-sm-1  information-message-text">
          {message}
        </div>
      </div>
    </div>
  );
};

export default InformationMessage;
