import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import App from "./App";

import { Provider } from "react-redux";
import store from "./store";

import history from "./history";

import registerServiceWorker from "./serviceWorker";


const loadTealium = () => {
  const script = document.createElement("script");
  script.src = process.env.REACT_APP_TEALIUM_UTAG_URL;
  script.async = true;
  document.head.appendChild(script);
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

loadTealium();
registerServiceWorker();
