import { useEffect, useState } from "react";

import { connect } from "react-redux";
import { updateLoader } from "../store/culture/action";
import { getCulture } from "../store/culture/reducer";
import DropdownFlag from "./DropdownFlag";
import DropdownMenu from "./DropdownMenu";

const Header = (props) => {
  const [corporateRedirect, setCorporateRedirect] = useState(
    process.env.REACT_APP_CORPORATE_URL + process.env.REACT_APP_DEFAULT_URI
  );

  const [cultureList, setCultureList] = useState([]);

  const {
    item: { elements },
    modular_content: modular,
  } = props.culture.headerData;

  useEffect(() => {
    if (props.culture.country && props.culture.language) {
      setCorporateRedirect(
        `${process.env.REACT_APP_CORPORATE_URL}/${props.culture.country}/${props.culture.language}`
      );
    }
  }, [props.culture.country, props.culture.language]);

  useEffect(() => {
    let temp = [];

    const publishOptions = props.culture.elements.publish_options.value[0];
    const filterKeys = props.culture.modular[
      publishOptions
    ].elements.menu.value.map((i) => i.codename);

    const filteredObject = Object.keys(modular)
      .filter((key) => filterKeys.includes(key)) // Filter keys based on the filterKeys array
      .reduce((obj, key) => {
        obj[key] = modular[key]; // Build a new object with the filtered keys
        return obj;
      }, {});

    for (const country in filteredObject) {
      if (Object.hasOwnProperty.call(modular, country)) {
        const element = modular[country]["elements"];
        temp.push(element);
      }
    }
    setCultureList(temp);
  }, [
    modular,
    setCultureList,
    props.culture.elements.publish_options.value,
    props.culture.modular,
  ]);

  return (
    <header className="main-header fixed-top scroll-friendly">
      <div className="container">
        <nav className="navbar navbar-expand-lg align-items-center">
          <a className="navbar-brand" href={corporateRedirect || ""}>
            <img
              src={
                elements.logo_colored ? elements.logo_colored.value[0].url : ""
              }
              alt={elements.logo_colored_alt ? elements.logo_colored_alt : ""}
              title={
                elements.logo_colored_title ? elements.logo_colored_title : ""
              }
              className="logo-jetsmart img-fluid"
            />
          </a>
          <div className="d-xl-none d-lg-none d-flex">
            <ul className="navbar-nav d-flex mobile-navbar-countries nav-justified justify-content-center align-items-center">
              <li
                className="nav-item nav-countries dropdown d-flex h-100 align-middle justify-content-center align-items-center"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  href="/"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    minWidth: "82px",
                  }}
                >
                  <DropdownFlag country={props.culture.country} />
                  <span>
                    {typeof props.culture.country !== "undefined" &&
                    props.culture.country !== null &&
                    modular[props.culture.country]
                      ? modular[props.culture.country].elements.country.value
                      : ""}
                  </span>
                </a>
                {cultureList.length > 0 && (
                  <DropdownMenu
                    cultureList={cultureList}
                    updateLoader={updateLoader}
                  />
                )}
              </li>
            </ul>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <small>MENU</small>
              </span>
            </button>
          </div>
          <div className="col-xl-5 col-lg-4 d-xl-inline-block d-lg-inline-block d-none">
            &nbsp;
          </div>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav w-100 nav-justified justify-content-center align-items-center">
              <li className="nav-item dropdown d-flex h-100 align-middle justify-content-center align-items-center">
                <a
                  className="nav-link"
                  href={
                    elements.link_register ? elements.link_register.value : ""
                  }
                >
                  <span className="hover-border-bottom-center">
                    {elements.text_link_register
                      ? elements.text_link_register.value
                      : ""}
                  </span>
                </a>
                {cultureList.length > 0 && (
                  <DropdownMenu
                    cultureList={cultureList}
                    updateLoader={updateLoader}
                  />
                )}
              </li>
              <li className="nav-item d-flex h-100 align-middle justify-content-center align-items-center">
                <a
                  className="nav-link"
                  href={elements.link_login ? elements.link_login.value : ""}
                >
                  <span className="hover-border-bottom-center">
                    {elements.text_login ? elements.text_login.value : ""}
                  </span>
                </a>
              </li>
              <li className="nav-item nav-countries dropdown d-xl-flex d-lg-flex d-none h-100 align-middle justify-content-center align-items-center">
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  href="/"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <DropdownFlag country={props.culture.country} />

                  <span>
                    {typeof props.culture.country !== "undefined" &&
                    props.culture.country !== null &&
                    modular[props.culture.country]
                      ? modular[props.culture.country].elements.country.value
                      : ""}
                  </span>
                </a>
                {cultureList.length > 0 && (
                  <DropdownMenu
                    cultureList={cultureList}
                    updateLoader={updateLoader}
                  />
                )}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, { updateLoader })(Header);
