import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../assets/styles/multileg-styles.css";
import { getCulture } from "../store/culture/reducer";
import ClockSvg from "./ClockSvg";
import FlightNumberTitle from "./FlightNumberTitle";

const StatusVueloDemorado = (props) => {
  const {
    culture: { modular },
  } = props;
  const [oldSta, setOldSta] = useState("");
  const [oldStd, setOldStd] = useState("");
  useEffect(() => {
    setOldStd(
      new Date(props.vuelo.std).toUTCString().split(" ")[4].substring(0, 5)
    );
    setOldSta(
      new Date(props.vuelo.sta).toUTCString().split(" ")[4].substring(0, 5)
    );
  }, [props.vuelo.std, props.vuelo.sta]);
  return (
    <div>
      <div className="card shadow radius overflow-hidden">
        <div className="red-bg">
          <h4 className="white-text my-3 text-center font-weight-bold">
            {modular.retrasado.elements.status
              ? modular.retrasado.elements.status.value
              : ""}
          </h4>
        </div>
        <div className="card-body">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-3 col-lg-3 col-xl-4 px-0 px-md-2 mb-4 mb-md-0">
                <h5
                  id="flight-num"
                  className="font-weight-bold mb-0 text-center"
                >
                  <FlightNumberTitle
                    vuelo={props.vuelo}
                    kenticoText={props.culture.elements.flights_scc_wj.value}
                  />
                </h5>
                <div className="h6 text-center mb-0 mt-1 mx-lg-1 rt-carrier-operating">
                  {parse(props.vuelo.operatingCarrierText, {
                    trim: true,
                  })}
                </div>
                {props.vuelo.charter === 1 && (
                  <div className="h6 text-uppercase">
                    {parse(modular.vuelo_charter.elements.status.value, {
                      trim: true,
                    })}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-9 col-lg-9 col-xl-8 px-0 px-md-3">
                <div className="container">
                  <div className="row">
                    <div className="col-5 col-lg-4 col-xl-3 d-flex flex-column flex-md-row align-items-center justify-content-start justify-content-sm-between justify-content-xl-around">
                      <div className="d-flex flex-column order-2 justify-content-center align-items-center">
                        <div className="card__info-through card__info-top">
                          {oldStd}
                        </div>
                        <div className="card__info-top text-center card__info-top-mw">
                          {props.culture.language === "en"
                            ? "New time"
                            : props.culture.language === "pt"
                            ? "Novo tempo"
                            : "Nueva hora"}
                        </div>
                        <span
                          id="dep-date"
                          className="card__info-time d-flex justify-content-center align-items-center"
                          align="center"
                        >
                          <ClockSvg />
                          {props.vuelo.showDepartureTime}
                        </span>
                        <span
                          id="dep-text"
                          className="font-weight-bold "
                          align="center"
                        >
                          ({props.vuelo.departureStation})
                        </span>
                        <span
                          id="dep-date"
                          className="font-weight-bold ml-2"
                          align="center"
                        >
                          {props.vuelo.origenName}
                        </span>
                      </div>
                      <img
                        src={modular.retrasado.elements.origin_img.value[0].url}
                        className="order-1 order-md-2"
                        alt=""
                      />
                    </div>
                    <div className="col-2 col-lg-4 col-xl-6 details-icon d-flex">
                      <hr />
                    </div>
                    <div className=" col-5 col-lg-4 col-xl-3 text-center text-md-left d-flex flex-column flex-md-row align-items-center justify-content-start justify-content-sm-between justify-content-xl-around">
                      <img
                        src={
                          modular.retrasado.elements.destination_img.value[0]
                            .url
                        }
                        alt=""
                        className="mx-auto"
                      />
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="card__info-through card__info-top">
                          {oldSta}
                        </div>
                        <div className="card__info-top text-center card__info-top-mw">
                          {props.culture.language === "en"
                            ? "New time"
                            : props.culture.language === "pt"
                            ? "Novo tempo"
                            : "Nueva hora"}
                        </div>

                        <span
                          id="dep-date-dayDest"
                          className="card__info-time d-flex justify-content-center align-items-center"
                          align="center"
                        >
                          <ClockSvg />
                          {props.vuelo.showArrivalTime}
                        </span>

                        <span
                          id="arr-text"
                          className="font-weight-bold mb-0"
                          align="center"
                        >
                          ({props.vuelo.arrivalStation})
                        </span>
                        <span
                          id="arr-date"
                          className="font-weight-bold mb-0"
                          align="center"
                        >
                          {props.vuelo.destinoName}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, {})(StatusVueloDemorado);
