import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import getContentType from "../services/KenticoService.js";
import { updateCulture } from "../store/culture/action";

const KenticoLoaderComponent = (props) => {
  const { country, language } = useParams();

  useEffect(() => {
    const validateAndSet = async () => {
      const landingData = await getContentType(
        "flight_status_landing",
        `${language}_${country}`
      );

      const publishOptions = landingData.item.elements.publish_options.value[0];
      const isCultureVisible =
        landingData.modular_content[publishOptions].elements.visible.value[0]
          .codename;

      process.env.REACT_APP_ENV !== "prod" &&
        console.log(`Is culture ${country} visible: ${isCultureVisible}`);

      const headerData = await getContentType(
        "header",
        `${language}_${country}`
      );

      const footerData = await getContentType(
        "footer",
        `${language}_${country}`
      );

      const stationsData = await getContentType(
        "stations",
        `${language}_${country}`
      );

      props.updateCulture({
        country,
        language,
        url: `/${country}/${language}`,
        culture: `${language}_${country}`,
        elements: landingData.item.elements,
        modular: landingData.modular_content,
        loader: true,
        title: landingData.item.elements.metadata__title.value,
        metadata: landingData.item.elements.metadata__description.value,
        headerData,
        footerData,
        stationsData,
        isCultureVisible,
      });

      return (
        <Redirect
          to={`${country}/${language}/${process.env.REACT_APP_ROUTE_NAME}`}
        />
      );
    };
    country && language && validateAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, language]);

  return <></>;
};

export default connect(null, { updateCulture })(KenticoLoaderComponent);
