import React, { useEffect, useState } from "react";
import "../assets/styles/sprite-banderas.css";
const DropdownFlag = ({ country }) => {
  const [backgroundPosition, setBackgroundPosition] = useState("");
  useEffect(() => {
    const countryFlagList = [
      {
        country: "cl",
        coordX: "-236px",
        coordY: "-133px",
      },
      {
        country: "br",
        coordX: "-409px",
        coordY: "-210px",
      },
      {
        country: "co",
        coordX: "-121px",
        coordY: "-211px",
      },
      {
        country: "ar",
        coordX: "-236px",
        coordY: "-210px",
      },
      {
        country: "pe",
        coordX: "-179px",
        coordY: "-210px",
      },
      {
        country: "py",
        coordX: "-294px",
        coordY: "-56px",
      },
      {
        country: "us",
        coordX: "-294px",
        coordY: "-210px",
      },
      {
        country: "uy",
        coordX: "-351px",
        coordY: "-55px",
      },
      {
        country: "ec",
        coordX: "-121px",
        coordY: "-133px",
      },
    ];
    const found = countryFlagList.find((c) => c.country === country);
    found
      ? setBackgroundPosition(`${found.coordX} ${found.coordY}`)
      : setBackgroundPosition("");
  }, [country]);

  return (
    <div className="flag rounded-pin  border-white-2 svg-container">
      <div className="cropped-square" style={{ backgroundPosition }} />
    </div>
  );
};

export default DropdownFlag;
