export const updateResults = (payload) => {
  return {
    type: "UPDATE_RESULTS",
    payload,
  };
};

export const updateResultSearch = (payload) => {
  return {
    type: "UPDATE_RESULTS_SEARCH",
    payload,
  };
};

export const updateResultsEditado = (payload) => {
  return {
    type: "UPDATE_RESULTS_EDITADO",
    payload,
  };
};

export const updateResultsFechaConsulta = (payload) => {
  return {
    type: "UPDATE_RESULTS_FECHA_CONSULTA",
    payload,
  };
};

export const updateResultsFlightNumber = (payload) => {
  return {
    type: "UPDATE_RESULTS_FLIGHT_NUMBER",
    payload,
  };
};

export const updateResultsSegmentSelected = (payload) => {
  return {
    type: "UPDATE_RESULTS_SEGMENT_SELECTED",
    payload,
  };
};

export const resetResultsState = (payload) => {
  return {
    type: "RESET_RESULTS_STATE",
    payload,
  };
};

export const updateResultsOrigen = (payload) => {
  return {
    type: "UPDATE_RESULTS_ORIGEN",
    payload,
  };
};

export const updateResultsDestino = (payload) => {
  return {
    type: "UPDATE_RESULTS_DESTINO",
    payload,
  };
};

export const updateOptionsDestino = (payload) => {
  return {
    type: "UPDATE_DESTINO_OPTIONS",
    payload,
  };
};
