import { useEffect, useRef } from "react";
import TagManager from "react-gtm-module";
//Redux
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Banner from "./components/Banner";
import Footer from "./components/Footer";
import Header from "./components/Header";
import KenticoLoaderComponent from "./components/KenticoLoaderComponent";

import Helmet from "react-helmet";
import MasterPage from "./components/MasterPage";
import Resultados from "./components/Resultados";
import { updateCulture } from "./store/culture/action";
import { getCulture } from "./store/culture/reducer.js";

const App = (props) => {
  const { culture } = props;
  const { isCultureVisible } = culture;
  const loadingRef = useRef(null);

  console.log("Version: JAT 1.0.50");

  useEffect(() => {
    // Initialize Google Tag Manager
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM,
    });
  }, []);

  useEffect(() => {
    // Create loader
    if (culture.loader) {
      loadingRef.current = window.newRtLoader(".loading", {
        logo_color: "white", // white or colored
        background_color: "rgba(255,255,255,.97)", // any color in hex or rgb/rgba
        airplane_color: "#163a70", // any color in hex
        dots_color: "#163a70", // any color in hex
      });
    }
  }, [culture.loader]);

  if (!culture.loader) {
    return (
      <Switch>
        <Route
          exact
          path={`/:country/:language/:ruta`}
          component={KenticoLoaderComponent}
        />
        <Redirect
          from="/"
          to={`${process.env.REACT_APP_DEFAULT_URI}/${process.env.REACT_APP_ROUTE_NAME}`}
        />
      </Switch>
    );
  }

  const ContentComponent = () => (
    <>
      <Banner />
      <Switch>
        <Route exact path={`/:country/:language/:ruta`}>
          <MasterPage loadingRef={loadingRef} />
        </Route>
        <Route
          path="/:country/:language/:ruta/resultados"
          component={Resultados}
        />
      </Switch>
    </>
  );

  return (
    <div className="App">
      <Helmet>
        <title>{culture.title}</title>
        <meta name="description" content={culture.metadata} />
      </Helmet>
      <Header />

      <div className="rt-widget">
        <div className="loading">
          {"yes" !== isCultureVisible ? null : <ContentComponent />}
        </div>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, { updateCulture })(App);
