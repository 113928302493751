import parse from "html-react-parser";
import React from "react";
import { connect } from "react-redux";
import { getCulture } from "../store/culture/reducer";
const StatusVueloSinDatos = (props) => {
  const {
    culture: { elements },
    handleBack,
  } = props;
  return (
    <div id="" className="col-12 mb-4">
      <div className="card shadow radius overflow-hidden">
        <div className="dark-blue-bg">
          <h4 className="white-text my-3 text-center font-weight-bold">
            {elements.message_without_results
              ? parse(elements.message_without_results.value, {
                  trim: true,
                })
              : ""}
          </h4>
        </div>
        <div className="card-body">
          <div className="container results-information">
            {/* ROW JS */}
            <div className="row mb-1">
              <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
                <img
                  src={elements.image_aa_search.value[0].url}
                  className="img-fluid p-1"
                  alt="img"
                />
              </div>
              <div className="col-12 col-md-8 d-flex justify-content-between flex-column py-md-4">
                <div className="results-information-text">
                  {elements.message_without_results_aa.value}
                </div>
                <div className="text-start py-1">
                  <button
                    className="btn dark-blue-bg text-white btn-information d-flex justify-content-between align-items-center btn-js"
                    type="submit"
                    onClick={handleBack}
                  >
                    <span className="p-sm-1">{elements.btn_aa.value}</span>
                    <i
                      className="fal fa-chevron-circle-right"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            {/* ROW AA */}
            <div className="row mt-1">
              <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
                <img
                  src={elements.image_new_search.value[0].url}
                  className="img-fluid p-1"
                  alt="img"
                />
              </div>
              <div className="col-12 col-md-8 d-flex justify-content-between flex-column py-md-4">
                <div className="results-information-text">
                  {elements.message_new_search.value}
                </div>
                <div className="text-start py-1">
                  <a
                    href={process.env.REACT_APP_AA_REDIRECT_URL || ""}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      className="btn dark-blue-bg text-white btn-information d-flex justify-content-between align-items-center btn-aa"
                      type="submit"
                    >
                      <span className="p-sm-1">
                        {elements.btn_new_search.value}
                      </span>
                      <i
                        className="fal fa-chevron-circle-right"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            {/* ROW INFORMATION */}
            <div className="row mt-1 results-footer-text">
              <div className="col-12">{elements.message_aa_search.value}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, {})(StatusVueloSinDatos);
