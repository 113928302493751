import axios from "axios";
import parse from "html-react-parser";
import $ from "jquery";

import moment from "moment";
import "moment/min/locales";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-invisible-recaptcha";
//Redux
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CalendarFs from "../components/CalendarFs.js";
//Services
import Tooltip from "rc-tooltip";
import { getCulture } from "../store/culture/reducer";
import {
  resetResultsState,
  updateOptionsDestino,
  updateResultSearch,
  updateResults,
  updateResultsDestino,
  updateResultsFechaConsulta,
  updateResultsFlightNumber,
  updateResultsOrigen,
  updateResultsSegmentSelected,
} from "../store/results/action.js";
//Components
import "../assets/styles/connection-flight.css";
import { getResults } from "../store/results/reducer.js";
import InformationMessage from "./InformationMessage.jsx";
import SelectsRuta from "./SelectsRuta.js";

const MasterPage = (props) => {
  const langCulture = props.culture.language;

  const [nombreDestino, setNombreDestino] = useState("");
  const [campoRequeridoRuta, setCampoRequeridoRuta] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [errorsflightRuta, setErrorsflightRuta] = useState(false);
  const [errorsflightRutaOrigin, setErrorsflightRutaOrigin] = useState(false);
  const [errorsFlightRutaDestination, setErrorsFlightRutaDestination] =
    useState(false);
  const [errorsFlightNumber, setErrorsFlightNumber] = useState(false);

  const location = useLocation();
  const dias = parseInt(process.env.REACT_APP_CALENDAR_DAYS);

  const mainRef = useRef(null);

  const history = useHistory();

  const [width, setWidth] = useState(window.innerWidth);
  const {
    culture,
    results,
    updateResultSearch,
    updateResultsSegmentSelected,
    resetResultsState,
    updateResultsOrigen,
    updateResultsFlightNumber,
    updateResultsDestino,
    loadingRef,
    updateOptionsDestino,
  } = props;

  const { elements, modular, country, language } = culture;

  const checkRuta = results.segmentSelected === "ruta";
  const checkVuelo = results.segmentSelected === "vuelo";

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    // Scroll a top solo en reload
    $(window).on("beforeunload", function () {
      $(window).scrollTop(0);
    });
  }, []);

  // Recaptcha
  //const recaptchaRef = useRef();
  const recaptchaRef: ReCAPTCHA = useRef();
  const [errorCaptcha, setErrorCaptcha] = useState(true);

  //ReCAPTCHA event
  const noErrorCaptchaDetected = () => {
    setErrorCaptcha(false);
  };

  //ReCAPTCHA event
  const errorCaptchaDetected = () => {
    setErrorCaptcha(true);
  };

  // revisar la cultura
  const cultura =
    culture.headerData.modular_content[culture.country].elements.country.value;
  const langFecha =
    culture.headerData.modular_content[culture.country].elements.language.value;

  moment.locale(langFecha);

  // valida el campo flightnumber solo numero
  const validateFlightNumber = (event) => {
    // const keyCode = Number(event.target.value);
    // const keyValue = String.fromCharCode(keyCode);

    if (
      event.target.value.length > 3 ||
      (event.which !== 8 &&
        event.which !== 0 &&
        (event.which < 48 || event.which > 57))
    ) {
      event.preventDefault();
      return false;
    }
  };

  const validaSelectRuta = () => {
    setErrorsflightRuta(false);
    setErrorsFlightRutaDestination(false);
  };

  const validaSelectRutaOrigin = (e) => {
    setErrorsflightRutaOrigin(false);
    setErrorsFlightRutaDestination(false);
  };

  const limpiar = (e) => {
    e && e.preventDefault();
    resetResultsState();
    updateResultsSegmentSelected(checkRuta ? "ruta" : "vuelo");
    setErrorsflightRuta(false);
    setErrorsflightRutaOrigin(false);
    setErrorsFlightRutaDestination(false);
    setErrorsFlightNumber(false);
    updateResultSearch(false);

    // Scroll a form
    mainRef.current && mainRef.current.scrollIntoView();
  };

  // const handleEditSearch = () => {
  //   // updateResultsEditado(false);

  //   setTimeout(() => {
  //     mainRef.current && mainRef.current.scrollIntoView();
  //   }, 1000);
  // };

  const handleSearchNewFlight = () => {
    resetResultsState();
    // updateResultsFechaConsulta(new Date());

    setTimeout(() => {
      mainRef.current && mainRef.current.scrollIntoView();
    }, 1000);
  };

  results.search && handleSearchNewFlight();

  // origen
  const [posts, setPosts] = useState([]);
  const [connectionRedirect, setConnectionRedirect] = useState({
    text1: "",
    text2: "",
    text3: "",
  });

  useEffect(() => {
    const emptyMsg = {
      text1: "",
      text2: "",
      text3: "",
    };
    const msgOptions = [
      {
        language: "es",
        text1: "Si el vuelo es con escala revisa el estado de tu vuelo",
        text2: " AQUÍ ",
        text3: "o búscalo por número de vuelo.",
      },
      {
        language: "pt",
        text1:
          "Se o seu vôo for um vôo de conexão, verifique o status do seu vôo ",
        text2: " AQUI ",
        text3: "ou pesquisar por número de vôo.",
      },
      {
        language: "en",
        text1: "If your flight has a stopover, check your flight status",
        text2: " HERE ",
        text3: "or search by flight number.",
      },
    ];
    const found = msgOptions.find(
      ({ language }) => language === props.culture.language
    );
    const msg = found ? found : emptyMsg;
    setConnectionRedirect(msg);
  }, [props.culture.language]);

  // luego de cargar toda la vista
  useEffect(() => {
    const cargarOrigenes = async () => {
      const urlApiTramos = process.env.REACT_APP_ENDPOINT_API_TRAMOS;
      axios
        .get(urlApiTramos)
        .then((res) => {
          setPosts(res.data);
        })
        .catch((err) => {
          console.log("Error: Api getCiudades ", err);
        });
    };

    cargarOrigenes();
  }, []);

  useLayoutEffect(() => {}, [location.pathname]);

  // cargar select de Origenes

  //RADIO RUTA
  const handleChangeRadioButtonRuta = (e) => {
    updateResultsSegmentSelected("ruta");
    updateResultsFlightNumber("");
    setCampoRequeridoRuta(true);
  };

  ////////////////////////////////////////////////RADIO VUELO
  const handleChangeRadioButtonVuelo = (e) => {
    updateResultsSegmentSelected("vuelo");
    updateResultsDestino("");
    updateResultsOrigen("");
    updateOptionsDestino([]);
    setErrorsflightRuta(false);
    setErrorsflightRutaOrigin(false);
    setErrorsFlightRutaDestination(false);
    setErrorsFlightNumber(false);

    setCampoRequeridoRuta(false);
  };

  const resuelveNombreCiudad = (codigoCiudad, posts) => {
    let nombreCiudad = "";

    for (let index = 0; index < posts.length; index++) {
      if (posts[index].id === codigoCiudad) {
        nombreCiudad = posts[index].name;
      }
    }

    return nombreCiudad;
  };

  const getOperatingCarrierText = (operatingCarrierCode) => {

    const carrierCodes = [
      {
        carrier: "JZ",
        text: modular.owning_carrier.elements.owning_carrier_jz.value,
      },
      {
        carrier: "WJ",
        text: modular.owning_carrier.elements.owning_carrier_wj.value,
      },
      {
        carrier: "JA",
        text: modular.owning_carrier.elements.owning_carrier_ja.value,
      },
      {
        carrier: "J6",
        text: modular.owning_carrier.elements.owning_carrier_j6.value,
      },
    ];

    const foundCarrier = carrierCodes.find(
      ({ carrier }) => carrier === operatingCarrierCode
    );

    let carrierText = foundCarrier
      ? foundCarrier.text
      : modular.owning_carrier.elements.owning_carrier_ja.value;
    return carrierText;
  };

  const enviarConsulta = async (event) => {
    setNombreDestino("");
    setErrorsFlightNumber("");

    window.$("html, body").animate({}, "slow");

    loadingRef.current.startLoader();

    event.preventDefault();
    window.$("#dev_modal").modal("hide");

    if (!errorCaptcha) {
      //variables de entorno
      const apikey = process.env.REACT_APP_APIKEY;
      const urlApi = process.env.REACT_APP_ENDPOINT_API;

      let anno = event.target.std.value.substring(6, 10);
      let mes = event.target.std.value.substring(3, 5);
      let dia = event.target.std.value.substring(0, 2);
      let std = anno + "-" + mes + "-" + dia;

      //'11-03-2021'

      let fechaStdingresada = new Date(mes + "/" + dia + "/" + anno);
      let fecha = moment(fechaStdingresada);
      let fechaMostrar = fecha.locale(props.culture.language).format("LL");

      let headers = {
        "Content-Type": "application/json",
        Accept: "*/*",
        "X-Api-Key": `${apikey}`,
      };

      //////////////////////////////////////////////////////////////////////////////////////////////
      //Busqueda por RUTA
      //////////////////////////////////////////////////////////////////////////////////////////////

      if (checkRuta) {
        const nroVuelo = "";
        const origen = event.target.origen.value;
        const destino = event.target.destino.value;

        if (origen !== "" && destino !== "") {
          // buscar el nombre de la ruta
          let ori = event.target.origen.value;
          let dest = event.target.destino.value;
          let nombreOri = "";
          let nombreDest = "";
          let idOri = "";
          let idDest = "";

          for (let index = 0; index < posts.length; index++) {
            if (posts[index].id === ori) {
              nombreOri = posts[index].name;
              idOri = posts[index].id;
            }
            if (posts[index].id === dest) {
              nombreDest = posts[index].name;
              idDest = posts[index].id;
            }
          }
          setNombreDestino(nombreDest);

          let url = `${urlApi}?flightNumber=${nroVuelo}&departureStation=${origen}&arrivalStation=${destino}&std=${std}`;

          try {
            const {
              data: { data },
            } = await axios.get(url, { headers });

            if (data.length > 0) {
              const fechaActualizacion = data[0].dateRegister;
              let fechaHoraActualiza = moment(new Date(fechaActualizacion));
              let fechaHoraHoy = moment(new Date());
              let difference = moment.duration(
                fechaHoraHoy.diff(fechaHoraActualiza)
              );
              const minutosActualizacion = Math.round(difference.asMinutes());
              setNombreDestino(
                resuelveNombreCiudad(data[0].arrivalStation, posts)
              );

              const vuelosObtenidos = data.map((flight) => {
                return {
                  ...flight,
                  showDepartureTime: new Date(flight.fechaHoraOrigen)
                    .toUTCString()
                    .split(" ")[4]
                    .substring(0, 5),
                  showArrivalTime: new Date(flight.fechaHoraDestino)
                    .toUTCString()
                    .split(" ")[4]
                    .substring(0, 5),
                  origenName: resuelveNombreCiudad(
                    flight.departureStation,
                    posts
                  ),
                  destinoName: resuelveNombreCiudad(
                    flight.arrivalStation,
                    posts
                  ),

                  operatingCarrierText: getOperatingCarrierText(
                    flight.operatingCarrier || flight.carrierCode
                  ),
                };
              });

              props.updateResults({
                resultadoVuelos: vuelosObtenidos,
                minutosActualizacion,
                nombreOrigenSel: nombreOri,
                nombreDestinoSel: nombreDest,
                fechaConsulta: fechaMostrar,

                idOrigen: idOri,
                labelOrigen: nombreOri + " (" + idOri + ")",
                idDestino: idDest,
                labelDestino: nombreDest + " (" + idDest + ")",
                nombreOrigen: nombreOri,
              });
            } else {
              props.updateResults({
                resultadoVuelos: [],
                minutosActualizacion: "",
                nombreOrigenSel: nombreOri,
                nombreDestinoSel: nombreDest,
                fechaConsulta: fechaMostrar,

                idOrigen: idOri,
                labelOrigen: nombreOri + " (" + idOri + ")",
                idDestino: idDest,
                labelDestino: nombreDest + " (" + idDest + ")",
                nombreOrigen: nombreOri,
              });
            }
            history.push(`/${country}/${language}/estado-de-vuelo/resultados`);
          } catch (error) {
            //error de api
            setMessageModal(
              parse(elements.error_message_flight_status.value, {
                trim: true,
              })
            );
            window.$("#dev_modal").modal("show");
            console.log("Response: No Data", error);
          }
        } else {
          if (origen === "") setErrorsflightRutaOrigin(true);
          if (destino === "") setErrorsFlightRutaDestination(true);
        }
      } else {
        //////////////////////////////////////////////////////////////////////////////////////////////
        //Busqueda por VUELO
        //////////////////////////////////////////////////////////////////////////////////////////////

        const nroVuelo = event.target.nroVuelo.value;

        if (nroVuelo !== "") {
          let url = `${urlApi}?flightNumber=${nroVuelo}&departureStation=&arrivalStation=&std=${std}`;

          try {
            const {
              data: { data },
            } = await axios.get(url, { headers });

            if (data.length > 0) {
              const fechaActualizacion = data[0].dateRegister;
              let fechaHoraActualiza = moment(new Date(fechaActualizacion));
              let fechaHoraHoy = moment(new Date());
              let difference = moment.duration(
                fechaHoraHoy.diff(fechaHoraActualiza)
              );
              const minutosActualizacion = Math.round(difference.asMinutes());
              setNombreDestino(
                resuelveNombreCiudad(
                  data[data.length - 1].arrivalStation,
                  posts
                )
              );

              const vuelosObtenidos = data.map((flight) => {
                return {
                  ...flight,
                  showDepartureTime: new Date(flight.fechaHoraOrigen)
                    .toUTCString()
                    .split(" ")[4]
                    .substring(0, 5),
                  showArrivalTime: new Date(flight.fechaHoraDestino)
                    .toUTCString()
                    .split(" ")[4]
                    .substring(0, 5),
                  origenName: resuelveNombreCiudad(
                    flight.departureStation,
                    posts
                  ),
                  destinoName: resuelveNombreCiudad(
                    flight.arrivalStation,
                    posts
                  ),
                  operatingCarrierText: getOperatingCarrierText(
                    flight.operatingCarrier || flight.carrierCode
                  ),
                };
              });

              props.updateResults({
                resultadoVuelos: vuelosObtenidos,
                minutosActualizacion,
                nombreOrigenSel: resuelveNombreCiudad(
                  data[0].departureStation,
                  posts
                ),
                nombreDestinoSel: resuelveNombreCiudad(
                  data[data.length - 1].arrivalStation,
                  posts
                ),
                fechaConsulta: fechaMostrar,
                flightNumber: event.target.flight.value,
              });
            } else {
              props.updateResults({
                resultadoVuelos: [],
                minutosActualizacion: "",
                nombreOrigenSel: "",
                nombreDestinoSel: "",
                fechaConsulta: fechaMostrar,
                flightNumber: event.target.flight.value,
              });
            }
            history.push(`/${country}/${language}/estado-de-vuelo/resultados`);
          } catch (error) {
            setMessageModal(
              parse(elements.error_message_flight_status.value, {
                trim: true,
              })
            );
            window.$("#dev_modal").modal("show");
            console.log("Response: No Data", error);
          }
        } else {
          setErrorsFlightNumber(true);
        }
      }
    } else {
      let mensaje = elements.recaptcha_error.value;
      setMessageModal(mensaje);
      window.$("#dev_modal").modal("show");
    }

    loadingRef.current.stopLoader();
  };
  return (
    <>
      <div className="container" id="card-container" ref={mainRef}>
        <div className="row mt-5">
          <div className="col-12 text-center">
            <h3 className="mb-0 font-weight-bold">
              {elements.title ? elements.title.value : ""}
            </h3>
          </div>

          {checkRuta && (
            <div className="col-12 text-center">
              <div className="font-weight-bold h5 mt-1">
                {/* {elements.message_multisegment
                  ? parse(elements.message_multisegment.value, {
                      trim: true,
                    })
                  : ""} */}
                {connectionRedirect.text1}
                <a
                  href={`${process.env.REACT_APP_CORPORATE_URL}/${props.culture.country}/${props.culture.language}/${process.env.REACT_APP_CONNECTION_FLIGHT_REDIRECT}`}
                  className="connection-flight-redirect"
                >
                  {connectionRedirect.text2}
                </a>
                {connectionRedirect.text3}
              </div>
            </div>
          )}
        </div>

        <div className="col-12 text-center">
          <div className=" font-weight-bold h6">
            {elements.message_hora
              ? parse(elements.message_hora.value, {
                  trim: true,
                })
              : ""}
          </div>
        </div>

        <div className="row mt-4 justify-content-center">
          <div className="col-12" id="groups-form">
            <div name="anchorsTopForms" className="group-card py-4">
              <form className="row" onSubmit={enviarConsulta}>
                <div className="container">
                  <div className="col-12 col-md-9 col-lg-6 mx-auto">
                    <div className="gray-bg-sm radius row p-sm-4">
                      <div className="col-12 text-center">
                        <h5 className="font-weight-bold">
                          {elements.search_title
                            ? elements.search_title.value
                            : ""}
                        </h5>
                      </div>

                      <div className="col-12 mx-auto">
                        <div className="group-form-fields">
                          <div className="row">
                            <div className="col-12 my-3">
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="customRadioInline1"
                                  value="customRadioInline1"
                                  name="customRadioInline1"
                                  className="custom-control-input"
                                  data-toggle="collapse"
                                  checked={checkRuta}
                                  aria-controls="collapse-return-date"
                                  onChange={handleChangeRadioButtonRuta}
                                />
                                <label
                                  className="custom-control-label group-radio-text"
                                  htmlFor="customRadioInline1"
                                >
                                  {elements.search_opt_1
                                    ? elements.search_opt_1.value
                                    : ""}
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="customRadioInline2"
                                  value="customRadioInline2"
                                  name="customRadioInline2"
                                  className="custom-control-input"
                                  data-toggle="collapse"
                                  checked={checkVuelo}
                                  aria-controls="collapse-return-date"
                                  onChange={handleChangeRadioButtonVuelo}
                                />

                                <label
                                  className="custom-control-label group-radio-text"
                                  htmlFor="customRadioInline2"
                                >
                                  {elements.search_opt_2
                                    ? elements.search_opt_2.value
                                    : ""}
                                </label>

                                {!isMobile && (
                                  <>
                                    <label
                                      data-for="registerTip"
                                      data-tip
                                      className="ml-2"
                                    >
                                      <img
                                        alt=""
                                        id="info"
                                        name="info"
                                        src={
                                          modular.aterrizo?.elements?.detail_img
                                            ? modular.aterrizo.elements
                                                .detail_img.value[0].url
                                            : ""
                                        }
                                        width="16"
                                        height="16"
                                      />
                                    </label>

                                    <ReactTooltip
                                      id="registerTip"
                                      place="top"
                                      effect="solid"
                                      html={false}
                                      backgroundColor="transparent"
                                    >
                                      <img
                                        alt=""
                                        src={
                                          elements.status_tooltip_img
                                            ? String(
                                                elements.status_tooltip_img
                                                  .value[0].url
                                              ) + "?auto=format&fm=jpg&w=450"
                                            : ""
                                        }
                                      />
                                    </ReactTooltip>
                                  </>
                                )}

                                {isMobile && (
                                  <Tooltip
                                    destroyTooltipOnHide={true}
                                    placement="top"
                                    trigger={["click"]}
                                    overlay={
                                      <img
                                        className="img-fluid"
                                        alt=""
                                        src={
                                          elements.status_tooltip_img
                                            ? String(
                                                elements.status_tooltip_img
                                                  .value[0].url
                                              ) + "?auto=format&fm=jpg&w=450"
                                            : ""
                                        }
                                      />
                                    }
                                  >
                                    <label data-tip className="ml-2">
                                      <img
                                        alt=""
                                        id="info"
                                        name="info"
                                        src={
                                          modular.aterrizo?.elements?.detail_img
                                            ? modular.aterrizo.elements
                                                .detail_img.value[0].url
                                            : ""
                                        }
                                        width="16"
                                        height="16"
                                      />
                                    </label>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                          <InformationMessage
                            message={elements.message_aa_search.value}
                            icon={elements.message_aa_search_icon.value[0].url}
                          />
                          <SelectsRuta
                            camposRequeridos={campoRequeridoRuta}
                            origen={results.nombreOrigen}
                            destino={nombreDestino}
                            idorigen={results.idOrigen}
                            iddestino={results.idDestino}
                            labelOrigen={results.labelOrigen}
                            labelDestino={results.labelDestino}
                            validaSelectRuta={validaSelectRuta}
                            validaSelectRutaOrigin={validaSelectRutaOrigin}
                            errorsflightRutaOrigin={errorsflightRutaOrigin}
                            checkRuta={checkRuta}
                            cultura={cultura}
                            editado={results.editado}
                            loadingRef={loadingRef}
                            errorsFlightRutaDestination={
                              errorsFlightRutaDestination
                            }
                          />
                          <div
                            id="opt-2"
                            className={`row ${checkRuta ? "d-none" : ""}`}
                          >
                            <div className="col-12 mt-3 text-left">
                              <label htmlFor="flight">
                                {elements.opt_2_flight
                                  ? elements.opt_2_flight.value
                                  : ""}
                              </label>

                              {/* INPUT FLIGHT NUMBER */}
                              <input
                                id="flight"
                                type="number"
                                name="nroVuelo"
                                onKeyPress={validateFlightNumber}
                                aria-label={
                                  elements.opt_2_flight
                                    ? elements.opt_2_flight.value
                                    : ""
                                }
                                placeholder={
                                  elements.opt_2_flight_placeholder
                                    ? elements.opt_2_flight_placeholder.value
                                    : ""
                                }
                                autoComplete="off"
                                onChange={(e) =>
                                  updateResultsFlightNumber(e.target.value)
                                }
                                value={results.flightNumber}
                                data-role="none"
                                // pattern="[0-9]*"
                                pattern="^[\d]+$"
                                onPaste={(e) => e.preventDefault()}
                              />

                              {errorsFlightNumber && (
                                <span className="text-danger text-small d-block mb-2">
                                  {elements.opt_2_flight_error
                                    ? elements.opt_2_flight_error.value
                                    : ""}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 mt-3 text-left">
                              <label htmlFor="departure_date">
                                {elements.search_date
                                  ? elements.search_date.value
                                  : ""}
                              </label>
                              <CalendarFs
                                country={country}
                                langCulture={langCulture}
                                editado={results.editado}
                                dias={dias}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 text-center px-0 mx-auto mt-6">
                              <button className="btn btn-red" type="submit">
                                {elements.search_btn
                                  ? elements.search_btn.value
                                  : ""}
                                <i
                                  className="fal fa-chevron-circle-right pl-1 pl-sm-2"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                            {elements.clean_btn && (
                              <div className="col-12 mt-3 text-center">
                                <button
                                  className="btn btn-link"
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                  onClick={limpiar}
                                >
                                  {elements.clean_btn.value}
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_KEY_RECAPTCHA}
                              onResolved={() => noErrorCaptchaDetected()}
                              onLoaded={() => noErrorCaptchaDetected()}
                              onError={() => errorCaptchaDetected()}
                              onExpired={() => errorCaptchaDetected()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="dev_modal" className="modal modal-select">
        <div className="modal-content">
          <div className="modal-header">
            <img
              className="icon-modal"
              src="https://preview-assets-us-01.kc-usercontent.com:443/b2956330-c34f-0064-2c6f-27bd5c0147fc/bc429bc6-4968-4ed0-acf6-4f67889b9692/exclamation.gif"
              alt=""
            />
            <h3 className="mt-1 d-inline text-white">Atención</h3>
          </div>
          <div className="modal-text">
            <p id="dev_text">{messageModal}</p>
            <button id="modal_btn" className="modalBtn" data-dismiss="modal">
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
    results: getResults(state),
  };
};

export default connect(mapStateToProps, {
  updateResults,
  updateResultSearch,
  updateResultsFechaConsulta,
  updateResultsFlightNumber,
  updateResultsSegmentSelected,
  resetResultsState,
  updateResultsOrigen,
  updateResultsDestino,
  updateOptionsDestino,
})(MasterPage);
