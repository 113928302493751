import React from "react";
import { connect } from "react-redux";
import { getCulture } from "../store/culture/reducer";

const Banner = (props) => {
  const {
    culture: { elements },
  } = props;
  return (
    <div className="px-0">
      <img
        className="d-block mx-auto mb-4 img-fluid"
        alt="Banner"
        src={elements.banner_bg ? elements.banner_bg.value[0].url : ""}
        id="main-banner"
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, {})(Banner);
