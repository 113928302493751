import React, { Fragment } from "react";
import "../assets/styles/multileg-styles.css";
import StatusVueloATiempo from "./StatusVueloATiempo";
import StatusVueloAterrizo from "./StatusVueloAterrizo";
import StatusVueloCancelado from "./StatusVueloCancelado";
import StatusVueloDemorado from "./StatusVueloDemorado";
import StatusVueloEnVuelo from "./StatusVueloEnVuelo";

const VueloMultileg = (props) => {
  const { filtrados, nombreDestinoSel, nombreOrigenSel } = props;
  const departureStation = filtrados[0].departureStation;
  const arrivalStation = filtrados[filtrados.length - 1].arrivalStation;
  const flightNumber = filtrados[0].flightNumber;
  const carrierCode = filtrados[0].carrierCode;

  process.env.REACT_APP_ENV !== "prod" &&
    console.log(filtrados, "VUELOS EN MULTILEG");
  return (
    <div className="card card--group col-12">
      <h3 className="text-center mt-1">
        {carrierCode} {flightNumber}
      </h3>
      <div className="text-center mb-6">
        <h3>
          {nombreOrigenSel} ({departureStation}) - {nombreDestinoSel} (
          {arrivalStation})
        </h3>
      </div>

      <div className="card__content">
        <div className="group">
          <>
            {props.filtrados.map((vuelo, index) => (
              <Fragment key={index}>
                <div
                  id="landed"
                  className="col-12 mb-4 multileg"
                  hidden={vuelo.estado !== "Cancelado"}
                >
                  <StatusVueloCancelado
                    vuelo={vuelo}
                    nombreOrigenSel={props.nombreOrigenSel}
                    nombreDestinoSel={props.nombreDestinoSel}
                  />
                </div>
                <div
                  id="landed"
                  className="col-12 mb-4 multileg"
                  hidden={vuelo.estado !== "Aterrizó"}
                >
                  <StatusVueloAterrizo
                    vuelo={vuelo}
                    nombreOrigenSel={props.nombreOrigenSel}
                    nombreDestinoSel={props.nombreDestinoSel}
                  />
                </div>
                <div
                  id="landed"
                  className="col-12 mb-4 multileg"
                  hidden={vuelo.estado !== "En vuelo"}
                >
                  <StatusVueloEnVuelo
                    vuelo={vuelo}
                    nombreOrigenSel={props.nombreOrigenSel}
                    nombreDestinoSel={props.nombreDestinoSel}
                  />
                </div>
                <div
                  className="col-12 mb-4 multileg"
                  hidden={vuelo.estado !== "Demorado"}
                >
                  <StatusVueloDemorado
                    vuelo={vuelo}
                    nombreOrigenSel={props.nombreOrigenSel}
                    nombreDestinoSel={props.nombreDestinoSel}
                  />
                </div>
                <div
                  id="landed"
                  className="col-12 mb-4 multileg"
                  hidden={vuelo.estado !== "A Tiempo"}
                >
                  <StatusVueloATiempo
                    vuelo={vuelo}
                    nombreOrigenSel={props.nombreOrigenSel}
                    nombreDestinoSel={props.nombreDestinoSel}
                  />
                </div>
              </Fragment>
            ))}
          </>
        </div>
      </div>
    </div>
  );
};

export default VueloMultileg;
