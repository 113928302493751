import React from "react";

const ClockSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4003 11.5253L11.5253 10.4003L8.78751 7.68141V4.28751H7.21251V8.29971L10.4003 11.5253ZM8.00001 15.4997C6.9626 15.4997 5.98761 15.3029 5.07501 14.9093C4.16241 14.5157 3.36861 13.9814 2.69361 13.3064C2.01861 12.6314 1.48431 11.8376 1.09071 10.925C0.697105 10.0124 0.500305 9.03741 0.500305 8.00001C0.500305 6.9626 0.697105 5.98761 1.09071 5.07501C1.48431 4.16241 2.01861 3.36861 2.69361 2.69361C3.36861 2.01861 4.16241 1.48431 5.07501 1.09071C5.98761 0.697105 6.9626 0.500305 8.00001 0.500305C9.03741 0.500305 10.0124 0.697105 10.925 1.09071C11.8376 1.48431 12.6314 2.01861 13.3064 2.69361C13.9814 3.36861 14.5157 4.16241 14.9093 5.07501C15.3029 5.98761 15.4997 6.9626 15.4997 8.00001C15.4997 9.03741 15.3029 10.0124 14.9093 10.925C14.5157 11.8376 13.9814 12.6314 13.3064 13.3064C12.6314 13.9814 11.8376 14.5157 10.925 14.9093C10.0124 15.3029 9.03741 15.4997 8.00001 15.4997ZM8.00001 13.9247C9.63741 13.9247 11.0342 13.3466 12.1904 12.1904C13.3466 11.0342 13.9247 9.63741 13.9247 8.00001C13.9247 6.36261 13.3466 4.96581 12.1904 3.8096C11.0342 2.6534 9.63741 2.07531 8.00001 2.07531C6.36261 2.07531 4.96581 2.6534 3.8096 3.8096C2.6534 4.96581 2.07531 6.36261 2.07531 8.00001C2.07531 9.63741 2.6534 11.0342 3.8096 12.1904C4.96581 13.3466 6.36261 13.9247 8.00001 13.9247Z"
        fill="#00B0CA"
      />
    </svg>
  );
};

export default ClockSvg;
