let initialState = {
  culture: {
    country: process.env.REACT_APP_DEFAULT_COUNTRY,
    language: process.env.REACT_APP_DEFAULT_LANGUAGE,
    url: process.env.REACT_APP_DEFAULT_URI,
    culture: process.env.REACT_APP_DEFAULT_CULTURE,
    elements: {},
    modular: {},
    loader: false,
    title: "",
    metadata: "",
    footerData: {},
    headerData: {},
    stationsData: {},
    isCultureVisible: "",
  },
};

export const getCulture = (state) => {
  return state.cultureReducer.culture;
};

const cultureReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_LOADER":
      return {
        ...state,
        culture: {
          ...state.culture,
          loader: action.payload,
        },
      };
    case "UPDATE_CULTURE":
      return {
        ...state,
        culture: action.payload,
      };

    default:
      return state;
  }
};

export default cultureReducer;
