import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";

import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import "../assets/styles/dc-datepicker.scss";
import { updateResultsFechaConsulta } from "../store/results/action";
import { getResults } from "../store/results/reducer";
// require("dotenv").config();
registerLocale("es", es);
registerLocale("pt", pt);
registerLocale("en", en);

/////////////////////////////////////////////////////

const CalendarFs = (props) => {
  const { updateResultsFechaConsulta, results, country, langCulture } = props;
  const calendarLanguage = `${langCulture}-${country.toUpperCase()}`;
  
  const today = new Date();
  let fechaIni = new Date();
  let fechaFin = new Date();

  fechaIni.setDate(today.getDate() - props.dias);
  fechaFin.setDate(today.getDate() + props.dias);

  const formatDate = (date) => {
    const formattedDay = String(date).padStart(2, "0");
    return formattedDay;
  };

  const customHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    // Get the month name (e.g., "July")
    const monthName = date.toLocaleString(calendarLanguage, {
      month: "long",
    });

    // Get the year (e.g., "2023")
    const year = date.getFullYear();

    return (
      <div class="react-datepicker__header">
        <button
          disabled={prevMonthButtonDisabled}
          onClick={decreaseMonth}
          type="button"
          class="react-datepicker__navigation react-datepicker__navigation--previous"
          aria-label="Previous Month"
        >
          <span class="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
            Previous Month
          </span>
        </button>
        <button
          disabled={nextMonthButtonDisabled}
          onClick={increaseMonth}
          type="button"
          class="react-datepicker__navigation react-datepicker__navigation--next"
          aria-label="Next Month"
        >
          <span class="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
            Next Month
          </span>
        </button>
        <div class="react-datepicker__current-month">
          <span style={{ fontWeight: 700 }}>{monthName}</span> {year}
        </div>
      </div>
    );
  };

  return (
    <DatePicker
      renderCustomHeader={customHeader}
      renderDayContents={(day) => formatDate(day)}
      showPopperArrow={false}
      selected={results.fechaConsultaBack}
      onChange={(date) => updateResultsFechaConsulta(date)}
      minDate={fechaIni}
      maxDate={fechaFin}
      showDisabledMonthNavigation
      dateFormat="dd/MM/yyyy"
      name="std"
      locale={langCulture}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      onChangeRaw={(e) => e.preventDefault()}
      formatWeekDay={(day) => day.substring(0, 3).toUpperCase()}
      calendarStartDay={langCulture === "es" ? 1 : 0}
      dayClassName={() => "dg-dp-square dg-dp-date"}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    results: getResults(state),
  };
};

export default connect(mapStateToProps, {
  updateResultsFechaConsulta,
})(CalendarFs);
