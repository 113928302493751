import parse from "html-react-parser";
import { connect } from "react-redux";
import { getCulture } from "../store/culture/reducer";
import FlightNumberTitle from "./FlightNumberTitle";

const StatusVueloCancelado = (props) => {
  const {
    culture: { modular },
  } = props;
  return (
    <div className="card shadow radius overflow-hidden">
      <div className="dark-gray-bg">
        <div className="h4 white-text my-3 text-center font-weight-bold">
          {modular.cancelado.elements.status
            ? modular.cancelado.elements.status.value
            : ""}
        </div>
      </div>
      <div className="card-body">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-3 col-lg-3 col-xl-4 px-0 px-md-2 mb-4 mb-md-0">
              <div
                id="flight-num"
                className="h5 text-center font-weight-bold mb-0"
              >
                <FlightNumberTitle
                  vuelo={props.vuelo}
                  kenticoText={props.culture.elements.flights_scc_wj.value}
                />
              </div>
              <div className="h6 text-center mb-0 mt-1 mx-lg-1 rt-carrier-operating">
                {parse(props.vuelo.operatingCarrierText, {
                  trim: true,
                })}
              </div>
              {props.vuelo.charter === 1 && (
                <div className="h6 text-uppercase mb-0 ">
                  {parse(modular.vuelo_charter.elements.status.value, {
                    trim: true,
                  })}
                </div>
              )}
            </div>
            <div className="col-12 col-md-9 col-lg-6 col-xl-6 px-0 px-md-3">
              <div className="container">
                <div className="row">
                  <div className="col-5 col-lg-4 text-center text-md-left d-flex flex-column flex-md-row align-items-center justify-content-start justify-content-sm-between ">
                    <div className="d-flex flex-column order-2 order-md-1">
                      <span
                        id="dep-date"
                        className="font-weight-bold mb-0"
                        align="center"
                      >
                        {props.vuelo.showDepartureTime}
                      </span>
                      <span
                        id="dep-text"
                        className="font-weight-bold mb-0"
                        align="center"
                      >
                        ({props.vuelo.departureStation})
                      </span>
                      <span
                        id="dep-text"
                        className="font-weight-bold ml-2"
                        align="center"
                      >
                        {props.vuelo.origenName}
                      </span>
                    </div>

                    <img
                      src={modular.cancelado.elements.origin_img.value[0].url}
                      className="order-1 order-md-2 "
                      alt=""
                    />
                  </div>
                  <div className="col-2 col-lg-4 details-icon d-flex">
                    <hr />
                  </div>
                  <div className="col-5 col-lg-4 text-center text-md-left d-flex flex-column flex-md-row align-items-center justify-content-start justify-content-sm-between">
                    <img
                      src={
                        modular.cancelado.elements.destination_img.value[0].url
                      }
                      alt=""
                      className=" mr-lg-2 "
                    />
                    <div className="d-flex flex-column">
                      <span
                        id="arr-date"
                        className="font-weight-bold mb-0"
                        align="center"
                      >
                        {props.vuelo.showArrivalTime}
                      </span>
                      <span
                        id="arr-text"
                        className="font-weight-bold mb-0"
                        align="center"
                      >
                        ({props.vuelo.arrivalStation})
                      </span>
                      <span
                        id="arr-text"
                        className="font-weight-bold mb-0"
                        align="center"
                      >
                        {props.vuelo.destinoName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-xl-2 d-flex justify-content-center justify-content-md-end justify-content-xl-center">
              <div className="">
                <a
                  href={
                    modular.cancelado.elements.btn_url
                      ? modular.cancelado.elements.btn_url.value
                      : ""
                  }
                  className="btn btn-blue mobile-btn-blue"
                >
                  {modular.cancelado.elements.btn_text
                    ? modular.cancelado.elements.btn_text.value
                    : ""}

                  <i className="fal fa-chevron-circle-right ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, {})(StatusVueloCancelado);
