import parse from "html-react-parser";
import { connect } from "react-redux";
import { getCulture } from "../store/culture/reducer";
import FlightNumberTitle from "./FlightNumberTitle";

const StatusVueloATiempo = (props) => {
  const {
    culture: { modular },
  } = props;

  return (
    <div>
      <div className="card shadow radius overflow-hidden">
        <div className="light-blue-bg">
          <div className="h4 white-text my-3 text-center font-weight-bold">
            {modular.a_tiempo.elements.status
              ? modular.a_tiempo.elements.status.value
              : ""}
          </div>
        </div>
        <div className="card-body">
          <div className="container">
            <div className="row align-items-center ">
              <div className="col-12 col-md-3 col-lg-3 col-xl-4 px-0 px-md-2 mb-4 mb-md-0">
                <div
                  id="flight-num"
                  className="h5 font-weight-bold mb-0 text-center"
                >
                  <FlightNumberTitle
                    vuelo={props.vuelo}
                    kenticoText={props.culture.elements.flights_scc_wj.value}
                  />
                </div>
                <div className="h6 text-center mb-0 mt-1 mx-lg-1 rt-carrier-operating">
                  {parse(props.vuelo.operatingCarrierText, {
                    trim: true,
                  })}
                </div>
                {props.vuelo.charter === 1 && (
                  <div className="h6 text-uppercase">
                    {parse(modular.vuelo_charter.elements.status.value, {
                      trim: true,
                    })}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-9 col-lg-9 col-xl-8 px-0 px-md-3">
                <div className="container">
                  <div className="row">
                    <div className="col-5 col-lg-4 col-xl-3 text-center text-md-left d-flex flex-column flex-md-row align-items-center justify-content-start justify-content-sm-between justify-content-lg-around">
                      {/* Departure date segment */}
                      <div className="d-flex flex-column order-2 ">
                        <span
                          id="dep-date"
                          className="font-weight-bold mb-0"
                          align="center"
                        >
                          {props.vuelo.showDepartureTime}
                        </span>
                        <span
                          id="dep-text"
                          className="font-weight-bold mb-0"
                          align="center"
                        >
                          ({props.vuelo.departureStation})
                        </span>
                        <span
                          id="dep-date"
                          className="font-weight-bold "
                          align="center"
                        >
                          {props.vuelo.origenName}
                        </span>
                      </div>
                      <img
                        src={modular.a_tiempo.elements.origin_img.value[0].url}
                        className="order-1 order-md-2 "
                        alt=""
                      />
                    </div>
                    <div className="col-2 col-lg-4 col-xl-6 details-icon d-flex">
                      <hr />
                    </div>
                    {/* Arrival date segment */}
                    <div className="col-5 col-lg-4 col-xl-3 text-center text-md-left d-flex flex-column flex-md-row align-items-center justify-content-start justify-content-sm-between justify-content-lg-around">
                      <img
                        src={
                          modular.a_tiempo.elements.destination_img.value[0].url
                        }
                        alt=""
                        className=""
                      />
                      <div className="d-flex flex-column ">
                        {props.vuelo.staFecha && (
                          <span
                            id="dep-date-dayDest"
                            className="font-weight-bold mb-0"
                            align="center"
                          >
                            {props.vuelo.staFecha}
                          </span>
                        )}

                        <span
                          id="arr-date"
                          className="font-weight-bold mb-0"
                          align="center"
                        >
                          {props.vuelo.showArrivalTime}
                        </span>
                        <span
                          id="arr-text"
                          className="font-weight-bold mb-0"
                          align="center"
                        >
                          ({props.vuelo.arrivalStation})
                        </span>
                        <span
                          id="arr-date"
                          className="font-weight-bold mb-0"
                          align="center"
                        >
                          {props.vuelo.destinoName}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, {})(StatusVueloATiempo);
